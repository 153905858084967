.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, transform 0.3s;
  outline: none;
}

.theme-toggle:hover {
  transform: scale(1.1);
}

.theme-toggle.light {
  background-color: rgba(0, 0, 0, 0.05);
}

.theme-toggle.dark {
  background-color: rgba(255, 255, 255, 0.1);
}

.theme-toggle-container {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .theme-toggle-container {
    position: absolute;
    top: 20px;
    right: 70px;
  }
} 