/* Authentication Hell Styles */

body.auth-hell-body {
  margin: 0;
  background-color: #0f1117;
  min-height: 100vh;
  font-family: monospace;
}

.auth-hell-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: #0f1117;
}

.auth-hell-form-container {
  width: 100%;
  max-width: 500px;
  border: 1px solid #2a3c5d;
  border-radius: 5px;
  overflow: hidden;
  background-color: #0f1117;
  color: #e0e0e0;
  font-family: monospace;
}

.auth-hell-header-group {
  width: 100%;
  border-bottom: 1px solid #2a3c5d;
  padding: 20px;
}

.auth-hell-header-text {
  color: #85a8ff;
  font-size: 24px;
  font-family: monospace;
  margin: 0;
}

.auth-hell-error-container {
  background-color: rgba(255, 50, 50, 0.1);
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
}

.auth-hell-error-text {
  color: #ff8585;
  font-family: monospace;
  text-align: center;
  margin: 0;
}

.auth-hell-input-group {
  width: 100%;
  border-bottom: 1px solid #2a3c5d;
  padding: 20px;
}

.auth-hell-input-label {
  display: block;
  color: #85a8ff;
  font-size: 18px;
  margin-bottom: 10px;
  font-family: monospace;
}

.auth-hell-input {
  width: 100%;
  color: #85a8ff;
  font-size: 24px;
  font-family: monospace;
  padding: 5px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #2a3c5d;
  outline: none;
}

.auth-hell-cursor-button-container {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
}

.auth-hell-cursor-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.auth-hell-cursor {
  width: 30px;
  height: 5px;
  background-color: #85a8ff;
}

.auth-hell-link-container {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.auth-hell-link-button {
  color: #85a8ff;
  font-family: monospace;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.auth-hell-info-container {
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
}

.auth-hell-info-text {
  margin: 0 0 15px 0;
  color: #e0e0e0;
  font-family: monospace;
}

.auth-hell-info-list {
  margin: 0 0 15px 20px;
  color: #e0e0e0;
  font-family: monospace;
}

.auth-hell-info-list li {
  margin-bottom: 5px;
}

.auth-hell-actions-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
}

.auth-hell-action-button {
  background-color: #1f2b47;
  color: #85a8ff;
  border: 1px solid #2a3c5d;
  border-radius: 4px;
  padding: 10px 20px;
  font-family: monospace;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.auth-hell-action-button:hover {
  background-color: #2a3c5d;
}

.auth-hell-success-container {
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
  text-align: center;
}

.auth-hell-success-text {
  color: #7cff85;
  font-family: monospace;
  margin: 0 0 15px 0;
}

.auth-hell-success-text:last-child {
  margin-bottom: 0;
}

/* PIN Input Styles */
.auth-hell-pin-pad-container {
  width: 100%;
  max-width: 500px;
  border: 1px solid #2a3c5d;
  border-radius: 5px;
  overflow: hidden;
}

.auth-hell-pin-display {
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
}

.auth-hell-pin-label {
  color: #85a8ff;
  font-size: 18px;
  margin-bottom: 15px;
  font-family: monospace;
}

.auth-hell-pin-value-container {
  border-bottom: 1px solid #85a8ff;
  padding-bottom: 10px;
}

.auth-hell-pin-value {
  color: #85a8ff;
  font-size: 24px;
  letter-spacing: 10px;
  font-family: monospace;
}

.auth-hell-keypad-container {
  padding: 20px;
}

.auth-hell-keypad-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.auth-hell-keypad-button {
  width: 30%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  border: 1px solid #2a3c5d;
  border-radius: 5px;
  cursor: pointer;
}

.auth-hell-keypad-button:hover {
  background-color: rgba(42, 60, 93, 0.3);
}

.auth-hell-keypad-button-empty {
  width: 30%;
  height: 70px;
  margin-bottom: 15px;
}

.auth-hell-keypad-text {
  color: #85a8ff;
  font-size: 24px;
  font-family: monospace;
}

.auth-hell-action-row {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.auth-hell-action-button {
  width: 48%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2a3c5d;
  border-radius: 5px;
  cursor: pointer;
}

.auth-hell-action-button:hover {
  background-color: rgba(42, 60, 93, 0.3);
}

.auth-hell-action-button-text {
  color: #85a8ff;
  font-size: 24px;
  font-family: monospace;
}

/* Security Question Styles */
.auth-hell-questions-container {
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid #2a3c5d;
}

.auth-hell-question-option {
  padding: 15px;
  border: 1px solid #2a3c5d;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.auth-hell-question-option:hover {
  background-color: rgba(42, 60, 93, 0.3);
}

.auth-hell-question-text {
  color: #85a8ff;
  font-family: monospace;
}

.auth-hell-selected-question {
  border-color: #85a8ff;
  background-color: rgba(133, 168, 255, 0.1);
}

.auth-hell-back-to-site {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #85a8ff;
  font-family: monospace;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  z-index: 100;
} 