/* Dark Mode Styles */

body.dark-mode {
  background-color: #121212;
  color: #e0e0e0;
}

/* Header */
body.dark-mode .header {
  background-color: #1e1e1e;
  border-bottom: 1px solid #333;
}

body.dark-mode .logo {
  color: #e0e0e0;
}

body.dark-mode .nav a {
  color: #e0e0e0;
}

body.dark-mode .nav a:hover {
  background-color: #333;
}

body.dark-mode .hamburger,
body.dark-mode .hamburger::before,
body.dark-mode .hamburger::after {
  background-color: #e0e0e0;
}

/* Main Content */
body.dark-mode .section {
  background-color: #1e1e1e;
}

body.dark-mode .section h2 {
  color: #e0e0e0;
}

body.dark-mode .highlight {
  color: #a393f5;
  text-shadow: 0 0 8px rgba(163, 147, 245, 0.3);
}

/* Hero Section */
body.dark-mode .hero {
  background-color: #1a1a1a;
  background-image: linear-gradient(to bottom right, #1a1a1a, #252836);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

body.dark-mode .hero-title {
  color: #f0f0f0;
}

body.dark-mode .hero-subtitle {
  color: #d0d0d0;
}

body.dark-mode .hero-subtitle strong {
  color: #a393f5;
}

body.dark-mode .cta-button {
  background-color: #7c73ff;
  box-shadow: 0 4px 12px rgba(124, 115, 255, 0.3);
}

body.dark-mode .cta-button:hover {
  background-color: #6c63ff;
  box-shadow: 0 6px 16px rgba(124, 115, 255, 0.4);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

/* Skills Section */
body.dark-mode .skills {
  background-color: #252836;
  background-image: linear-gradient(to bottom, #252836, #1e1e2d);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

body.dark-mode .skills-title {
  color: #f0f0f0;
}

body.dark-mode .skills-subtitle {
  color: #d0d0d0;
}

body.dark-mode .skill-card {
  background-color: rgba(30, 30, 30, 0.7);
  backdrop-filter: blur(5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

body.dark-mode .skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
}

body.dark-mode .skill-card h3 {
  color: #a393f5;
}

body.dark-mode .skill-card p {
  color: #b0b0b0;
}

/* Projects Section */
body.dark-mode .section li {
  background-color: rgba(30, 30, 30, 0.7);
  backdrop-filter: blur(5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

body.dark-mode .section li:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.4);
}

body.dark-mode .section .auth-link {
  background-color: #7c73ff;
  box-shadow: 0 4px 12px rgba(124, 115, 255, 0.3);
}

body.dark-mode .section .auth-link:hover {
  background-color: #6c63ff;
  box-shadow: 0 6px 16px rgba(124, 115, 255, 0.4);
  transform: translateY(-2px);
  transition: all 0.3s ease;
  color: white;
}

/* Contact Form */
body.dark-mode .contact-form input,
body.dark-mode .contact-form textarea {
  background-color: #2d2d2d;
  border: 1px solid #444;
  color: #e0e0e0;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
}

body.dark-mode .contact-form input:focus,
body.dark-mode .contact-form textarea:focus {
  border-color: #7c73ff;
  box-shadow: 0 0 0 2px rgba(124, 115, 255, 0.2);
  outline: none;
}

body.dark-mode .contact-form button {
  background-color: #7c73ff;
  box-shadow: 0 4px 12px rgba(124, 115, 255, 0.3);
}

body.dark-mode .contact-form button:hover {
  background-color: #6c63ff;
  box-shadow: 0 6px 16px rgba(124, 115, 255, 0.4);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

/* Footer */
body.dark-mode .footer {
  background-color: #1a1a1a;
}

/* Footnote */
body.dark-mode .footnote a {
  color: #7c73ff;
  opacity: 0.7;
}

body.dark-mode .footnote a:hover {
  opacity: 1;
} 