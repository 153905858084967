/* Home.css */

.footnote{
  font-size: 10px;
  text-align: center;
  margin-top: 20px;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.footnote a {
  text-decoration: none;
  transition: color 0.3s ease, opacity 0.3s ease;
}

.footnote a:hover {
  opacity: 1;
}

.home {
  font-family: 'Arial', sans-serif;
  color: #333;
  padding: 20px;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  background-color: #f9f9f9;
  margin-bottom: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.hero-content {
  max-width: 600px;
}

.hero-title {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.highlight {
  color: #6c63ff;
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

.hero-subtitle {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 40px;
  transition: color 0.3s ease;
}

.hero-subtitle strong {
  transition: color 0.3s ease;
}

.cta-button {
  background-color: #6c63ff;
  color: white;
  padding: 15px 30px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1em;
  transition: all 0.3s ease;
  display: inline-block;
  box-shadow: 0 4px 6px rgba(108, 99, 255, 0.2);
}

.cta-button:hover {
  background-color: #5a52cc;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(108, 99, 255, 0.3);
}

.hero-image img {
  max-width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.hero-image:hover img {
  transform: scale(1.02);
}

.skills {
  padding: 50px 20px;
  background-color: #e6f7f8;
  border-radius: 10px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.skills-content {
  max-width: 1200px;
  margin: auto;
  text-align: center;
}

.skills-title {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.skills-subtitle {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 40px;
  transition: color 0.3s ease;
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-card {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  width: 250px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.skill-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.skill-card img {
  max-width: 50px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.skill-card:hover img {
  transform: scale(1.1);
}

.skill-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.skill-card p {
  font-size: 1em;
  line-height: 1.6;
  transition: color 0.3s ease;
}

/* About, Projects, and Contact section styles */

.section {
  padding: 50px 20px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.section h2 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  transition: color 0.3s ease;
}

.section p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.section ul {
  list-style-type: none;
  padding: 0;
}

.section li {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  transition: all 0.3s ease;
  border: 1px solid transparent;
}

.section li:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
}

.section .auth-link {
  margin-left: auto;
  background-color: #6c63ff;
  color: white;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9em;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(108, 99, 255, 0.2);
}

.section .auth-link:hover {
  background-color: #5a52cc;
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(108, 99, 255, 0.3);
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  transition: all 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #6c63ff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(108, 99, 255, 0.2);
}

.contact-form button {
  background-color: #6c63ff;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(108, 99, 255, 0.2);
}

.contact-form button:hover {
  background-color: #5a52cc;
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(108, 99, 255, 0.3);
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
  }

  .hero-content {
    margin-bottom: 20px;
  }

  .skills-grid {
    flex-direction: column;
    align-items: center;
  }

  .skill-card {
    width: 80%;
  }
  
  .section li {
    flex-direction: column;
    text-align: center;
  }
  
  .section .auth-link {
    margin: 10px auto 0;
  }
}