/* App.css */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
}

/* Light mode styles (default) */
body.light-mode {
  color: #333;
  background-color: #f5f5f5;
}

.header {
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  padding: 20px 0;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.nav {
  display: flex;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.nav a {
  color: #333;
  padding: 10px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
}

.nav a:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.menu-toggle.open .hamburger {
  transform: rotate(45deg);
}

.menu-toggle.open .hamburger::before {
  transform: rotate(90deg);
  top: 0;
}

.menu-toggle.open .hamburger::after {
  transform: rotate(90deg);
  top: 0;
}

.hamburger {
  width: 30px;
  height: 3px;
  background-color: #333;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #333;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.hamburger::before {
  top: -10px;
}

.hamburger::after {
  top: 10px;
}

.main-content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
}

.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
  position: relative;
  width: 100%;
  margin-top: auto;
}

.auth-link {
  color: #4a90e2;
  text-decoration: underline;
  margin-left: 8px;
  font-weight: bold;
}

.auth-link:hover {
  color: #2a6ac0;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
  }

  .menu-toggle {
    display: flex;
  }

  .nav {
    flex-direction: column;
    align-items: center;
    max-height: 0;
  }

  .nav.open {
    max-height: 300px;
  }

  .nav a {
    padding: 10px;
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 769px) {
  .nav {
    max-height: none;
  }

  .menu-toggle {
    display: none;
  }
}